import moment from 'moment';
import axios from "axios";

export class PushesService {

    static GetSchuledPushes(): Promise<Array<ScheduledPush>> {
        return axios.get(`/push-notifications/scheduled?pageSize=30&order=desc&pageNumber=1`)
            .then(async (res) => {
                return res.data as Array<ScheduledPush>;
            }).then(pushes => {
                return pushes.filter(push => moment(push.scheduledTime).isAfter(moment()))
            })
    }

    static GetScheduled(id: number): Promise<ScheduledPush> {
        return axios.get(`/push-notifications/scheduled/${id}`)
            .then(async (res) => {
                return res.data
            });
    }

    static GetHistoricPushes(): Promise<Array<HistoricPush>> {
        return axios.get(`/push-notifications/list`)

            .then(async (res) => {
                return res.data;
            });
    }

    static Create(body: string, categoryId: number, date: Date | undefined): Promise<Response> {
        const payload: any = {
            categoryId: categoryId,
            messageBody: body,
        };

        if (date) {
            const mDate = moment(date);
            mDate.seconds(0);
            mDate.milliseconds(0);
            payload.scheduledTime = mDate.toDate();
        }

        console.log("Creating push: ", JSON.stringify(payload));

        return axios.post(`/push-notifications/send`, JSON.stringify(payload));
    }

    static Update(id: number, body: string, categoryId: number, date: Date | undefined): Promise<Response> {
        const payload: any = {
            categoryId: categoryId,
            messageBody: body,
        };

        if (date) {
            const mDate = moment(date);
            mDate.seconds(0);
            mDate.milliseconds(0);
            payload.scheduledTime = mDate.toDate();
        }

        console.log("Updating push: ", JSON.stringify(payload));

        return axios.put(`/push-notifications/scheduled/${id}`, JSON.stringify(payload));
    }

    static Delete(id: number): Promise<Response> {
        return axios.delete(`/push-notifications/scheduled/${id}`);
    }

    static GetPushStatistics(): Promise<PushRangeStats> {
        return axios.get(`/push-notifications/stats`)
            .then(async (res) => {
                return res.data;
            });
    }
}

export type PushRangeStats = {
    androidCount: number;
    iosCount: number;
}

export type ScheduledPush = {
    scheduledPushId: number;
    pushNotificationCategoryId: number;
    scheduledPushTitle: string;
    scheduledPushBody: string;
    scheduledTime: string;
    createdBy: string;
    isSend: boolean;
}

export type HistoricPush = {
    pushNotificationId: number;
    pushNotificationTitle: string;
    pushNotificationBody: string;
    createdBy: string;
    sendTime: Date;
}

