import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, TextField, Typography} from '@material-ui/core';
import {DateTimePicker} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Category, CategoryService} from '../../services/CategoriesService';
import {useHistory, useParams} from 'react-router-dom';
import {PushesService} from '../../services/PushesService';
import moment from 'moment';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import dayjs, {Dayjs} from 'dayjs';

type NotificationsEditProps = {};

const NotificationsEdit = ({}: NotificationsEditProps) => {

    let {id} = useParams() as any;

    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const [categories, setCategories] = useState<Array<Category>>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [isTerminated, setIsTerminated] = useState(false);
    const [sendDate, setSendDate] = useState<Date>();
    const [body, setBody] = useState<any>();

    const history = useHistory();

    // Get the push to the server
    const sendPush = () => {
        setSelectedCategory(undefined);
        setBody(undefined);
        setIsLoading(true);
        setIsFinished(false);

        if (id) {
            PushesService.Update(id, body, selectedCategory?.pushNotificationCategoryId || -1, sendDate)
                .then((res) => {
                    console.log(res)
                    setIsFinished(true);
                    setTimeout(() => {
                        history.push("/push-notifications");
                    }, 2000)
                })
                .catch(e => {
                    alert("Leider konnte die Nachricht nicht übermittelt werden")
                    console.log(e)
                    setIsLoading(false);
                })
        }

        // Update existing push
        else {
            PushesService.Create(body, selectedCategory?.pushNotificationCategoryId || -1, sendDate)
                .then((res) => {
                    setIsFinished(true);
                    setTimeout(() => {
                        history.push("/push-notifications");
                    }, 2000)
                })
                .catch(e => {
                    alert("Leider konnte die Nachricht nicht übermittelt werden")
                    console.log(e)
                    setIsLoading(false);
                    setIsFinished(true);
                })
        }

    }

    // Get categories from API
    useEffect(() => {
        CategoryService.GetAll().then(categories => {
            setCategories(categories);
            if (id) {
                PushesService.GetScheduled(id).then(push => {
                    const category = categories.filter(c => c.pushNotificationCategoryId === push.pushNotificationCategoryId)[0];
                    console.log(category)
                    setSelectedCategory(category);
                    setBody(push.scheduledPushBody);
                    setIsTerminated(true)
                    setSendDate(moment(push.scheduledTime).toDate());
                })
            }
        })
    }, []);

    return (
        <div className={"w-full flex justify-center"}>
            <div className={"max-w-4xl"}>
                <div className={"flex justify-between mb-4"}>
                    <Typography variant={"h5"}>Push-Notification erstellen</Typography>
                </div>


                <Box style={{paddingTop: 16}}>
                    <Typography variant="body2">
                        Mit diesem Formular können Sie an all Ihre Nutzer einmalig eine Push-Benachrichtigung senden.
                        Bitte gehen Sie nachsichtig mit dieser Funktion um. Zu häufige Benachrichtigungen führen dazu,
                        dass
                        diese vom Nutzer deaktiviert werden (Spam).

                    </Typography>
                </Box>
                <Box  className="mt-5 mb-5">
                    <b>
                        ⚠️ Ein Abbrechen oder Zurückziehen von direkt versendeten Nachrichten ist nicht mehr
                        möglich.
                    </b>
                </Box>
                <Box>
                    <div>
                        <FormControl variant="filled" style={{minWidth: 300}}>
                            <InputLabel id="demo-simple-select-helper-label">Kategorie der Benachrichtigung</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                style={{marginBottom: 16}}
                                id="demo-simple-select"
                                variant="filled"
                                value={selectedCategory?.pushNotificationCategoryId || id ? 1 : undefined}
                                onChange={(event) => {
                                    const category = categories.filter(c => c.pushNotificationCategoryId === event.target.value).pop();
                                    setSelectedCategory(category);
                                }}
                            >
                                {categories.map(c => <MenuItem
                                    value={c.pushNotificationCategoryId}>{c.categoryShort} ({c.categoryIdentifier})</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            style={{minWidth: 300}}
                            id="standard-textarea"
                            variant="filled"
                            label="Inhalt der Nachricht"
                            value={body}
                            defaultValue={id ? " " : ""}
                            multiline
                            onChange={(e) => {
                                setBody(e.target.value);
                            }}
                        />
                    </div>
                    <div>
                        <FormControl>
                            <FormControlLabel
                                style={{marginTop: 8}}
                                control={
                                    <Checkbox
                                        checked={isTerminated}
                                        onChange={() => {
                                            if (!isTerminated) {
                                                setSendDate(new Date())
                                            }
                                            setIsTerminated(!isTerminated)
                                        }}
                                        name="isTerminated"
                                        color="primary"
                                    />
                                }
                                label="Versand terminieren"
                            />
                        </FormControl>
                    </div>
                    <div>
                        <DateTimePicker format={"dd.MM.yyyy HH:mm' Uhr'"} disablePast={true} value={sendDate}
                                        onChange={(d) => {
                                            setSendDate(d ?? new Date())
                                        }} disabled={!isTerminated}/>
                    </div>
                    <br/>

                    <Button onClick={sendPush} variant="contained" color="primary"
                            disabled={!selectedCategory || !body || isLoading}>
                        {id ? "Aktualisieren" : (isTerminated ? "Jetzt anlegen" : "Jetzt versenden")}
                    </Button>

                    {/** Loading indicator */}
                    {isLoading && (
                        <div style={{marginTop: 16}}>
                            <CircularProgress size={16}/> Das Versenden der Push-Benachrichtungen läuft...
                        </div>
                    )}

                    {/** Finished message */}
                    {isFinished && (
                        <div style={{marginTop: 16, display: 'flex', alignItems: 'center'}}>
                            <DoneAllIcon style={{color: 'green', marginRight: 8}}/> Push-Nachrichten wurden erfolgreich
                            versendet
                        </div>
                    )}
                </Box>
            </div>
        </div>
    )
};

export default NotificationsEdit;
