import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Box, Container } from '@material-ui/core';
import { useSignIn } from '../../queries/useSignIn';
import { SignInRequest } from '../../models/auth/SignInRequest';
import { useHistory } from 'react-router-dom';
import BackDropFullScreen from '../../components/BackDropFullScreen';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../stores/useAuth';

export default function SignInPage() {
  // const [email, setEmail] = useState("mm@app-pilots.de");
  // const [password, setPassword] = useState("hallowelt");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const mutationResult = useSignIn();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();

  const onSubmit = async () => {
    try {
      const signInDto: SignInRequest = { username: email, password };
      await mutationResult.mutateAsync(signInDto);
      enqueueSnackbar('Logged in successfully!', { variant: 'success' });
    } catch (e: any) {
      console.error(e.message);
      enqueueSnackbar(`Logged in with error: ${e.message}`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if ((mutationResult.isSuccess && !mutationResult.isLoading) || token) {
      history.push('/admins');
    }
  }, [mutationResult, history, token]);

  return (
    <Container className={'mt-16'} component='main' maxWidth='xs'>
      <div>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <form className={'w-full mt-2'} noValidate>
          <TextField
            value={email}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='email'
            autoFocus
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <TextField
            value={password}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />

          <Box my={2}>
            <Button
              disabled={mutationResult.isLoading}
              type='button'
              fullWidth
              variant='contained'
              color='primary'
              onClick={onSubmit}
            >
              Sign In
            </Button>
          </Box>

          <BackDropFullScreen isOpen={mutationResult.isLoading} />
        </form>
      </div>
    </Container>
  );
}
