import React, { useEffect, useState } from "react";
import { MenuItemDO } from "../../models/menu/MenuItemDO";
import { MenuItemType } from "../../models/menu/MenuItemType";
import { useSnackbar } from "notistack";
import {
  useCreateMenu,
  useGetMenu,
  useUpdateMenu,
} from "../../queries/useMenu";
import { useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Card,
  CardActionArea,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { WYSIWYGEditor } from "../../components/WYSIWYGEditor";
import { MenuItemUpdateRequestDO } from "../../models/menu/MenuItemUpdateRequestDO";
import { MenuItemCreateRequestDO } from "../../models/menu/MenuItemCreateRequestDO";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import { MenuItemNativeType } from "../../models/menu/MenuItemNativeType";

type MenuFormType = {
  menuItem?: MenuItemDO;
  isFormReset: boolean;
  onNewMenuItem: () => void;
};

type FormMenuType = {
  parentId?: number;
  menuItemId: number;
  titleGerman: string;
  titleEnglish: string;
  type: MenuItemType;
  icon: string;
  destination: string;
};

const formDataInit: FormMenuType = {
  parentId: 0,
  menuItemId: 0,
  titleGerman: "",
  titleEnglish: "",
  type: MenuItemType.internalWeb,
  icon: "",
  destination: "",
};

function MenuForm({ menuItem, isFormReset, onNewMenuItem }: MenuFormType) {
  const { enqueueSnackbar } = useSnackbar();
  const { data: menu, isLoading } = useGetMenu();
  const createMutationResult = useCreateMenu();
  const updateMutationResult = useUpdateMenu();
  const queryClient = useQueryClient();
  const requiredError = "Please fill out the field";
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormMenuType>();
  const [icon, setIcon] = useState("");

  useEffect(() => {
    if (menuItem) {
      formDataInit.parentId = menuItem.parentId;
      formDataInit.menuItemId = menuItem.menuItemId;
      formDataInit.titleGerman = menuItem?.title[0]?.value || "";
      formDataInit.titleEnglish = menuItem?.title[1]?.value || "";
      formDataInit.type = menuItem.type;
      formDataInit.icon = menuItem.icon;
      formDataInit.destination = menuItem.destination;
      setIcon(formDataInit.icon);
      reset(formDataInit);
    } else {
      onReset();
    }
  }, [menuItem]);

  useEffect(() => {
    if (isFormReset) {
      onReset();
    }
  }, [isFormReset]);

  const onReset = () => {
    reset({
      parentId: 0,
      menuItemId: 0,
      titleGerman: "",
      titleEnglish: "",
      type: MenuItemType.internalWeb,
      icon: "",
      destination: "",
    });
    setIcon("");
    onNewMenuItem();
  };

  const onChooseImage = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    const files = event.target.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setIcon(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
    onChange(event);
  };

  const formMenuType2MenuItem = (data: FormMenuType) => {
    const tempMenuItem: MenuItemCreateRequestDO | MenuItemUpdateRequestDO = {
      destination: data.destination,
      icon: icon !== "" && icon !== data.icon ? icon : data.icon,
      menuItemId: data.menuItemId,
      parentId: data.parentId === 0 ? undefined : data.parentId,
      title: [
        { languageIdentifier: "DE", value: data.titleGerman },
        { languageIdentifier: "EN", value: data.titleEnglish },
      ],
      type: data.type,
    };
    return tempMenuItem;
  };

  const onSubmit = async (data: FormMenuType) => {
    const tempMenuItem = formMenuType2MenuItem(data);

    try {
      if (menuItem) {
        await onEdit(tempMenuItem as MenuItemUpdateRequestDO);
      } else {
        await onCreate(tempMenuItem as MenuItemCreateRequestDO);
      }
    } catch (e: any) {
      console.error(e.message);
      enqueueSnackbar(`Error: ${e.message}`, {
        variant: "error",
      });
    }
  };

  const onEdit = async (data: MenuItemUpdateRequestDO) => {
    await updateMutationResult.mutateAsync(data);
    await queryClient.invalidateQueries("useGetMenu");
    enqueueSnackbar(`Menu ${data.title[0].value} updated successfully!`, {
      variant: "success",
    });
    onReset();
  };

  const onCreate = async (data: MenuItemCreateRequestDO) => {
    await createMutationResult.mutate(data);
    enqueueSnackbar(`Menu ${data.title[0].value} created successfully!`, {
      variant: "success",
    });
    onReset();
    setTimeout(async () => {
      await queryClient.invalidateQueries("useGetMenu");
    }, 1000);
  };

  const getMenuItemNativeTypes = () => {
    return Object.entries(MenuItemNativeType).map((e) => e[1]);
  };

  return (
    <div>
      <Box my={2}>
        <Typography component="h5" variant="h5">
          Create entry
        </Typography>
      </Box>

      <form className={"w-full"}>
        <div className={"grid grid-cols-2 gap-4"}>
          <div className={""}>
            <FormControl className={"w-full"}>
              <InputLabel id="parent">Parent (optional)</InputLabel>
              <Controller
                key={"parent"}
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      label={"parent"}
                      className={"p-1"}
                      {...field}
                      labelId="parent"
                      fullWidth
                      defaultValue={""}
                      error={!!errors?.parentId?.message}
                    >
                      {menu &&
                        menu.map((menuItem) => (
                          <MenuItem
                            key={menuItem.menuItemId.toString()}
                            value={menuItem.menuItemId}
                          >
                            {menuItem.title[0].value}
                          </MenuItem>
                        ))}
                    </Select>

                    {errors?.parentId?.message && (
                      <FormHelperText error>
                        {errors?.parentId?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
                name={"parentId"}
                rules={{
                  required: false,
                }}
                defaultValue={formDataInit.parentId}
              />
            </FormControl>
          </div>

          <div className={""}>
            <Controller
              key={"image"}
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  <Typography component="h5" variant="overline">
                    Icon
                  </Typography>

                  <Box mb={2}>
                    <Button variant="contained" component="label">
                      Upload Icon
                      <input
                        accept="image/*"
                        type="file"
                        hidden
                        onChange={(event) => onChooseImage(event, onChange)}
                      />
                    </Button>
                    {errors?.icon?.message && (
                      <FormHelperText error>
                        {errors?.icon?.message}
                      </FormHelperText>
                    )}
                  </Box>

                  <Box mt={1}>
                    {icon && (
                      <Card>
                        <CardActionArea>
                          <img src={icon} />
                        </CardActionArea>
                      </Card>
                    )}
                  </Box>
                </>
              )}
              name={"icon"}
              defaultValue={formDataInit.icon}
            />
          </div>
        </div>

        <div className={""}>
          <Box>
            <Typography variant="body1">
              <strong>Title in various languages</strong>
            </Typography>
          </Box>

          <div className={"grid grid-cols-2 gap-3"}>
            <Controller
              key={"titleGerman"}
              control={control}
              render={({ field }) => (
                <>
                  <div>
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="German"
                      autoCapitalize={"none"}
                    />
                    {errors?.titleGerman?.message && (
                      <FormHelperText error>
                        {errors?.titleGerman?.message}
                      </FormHelperText>
                    )}
                  </div>
                </>
              )}
              name={"titleGerman"}
              rules={{
                required: requiredError,
              }}
              defaultValue={formDataInit.titleGerman}
            />

            <Controller
              key={"titleEnglish"}
              control={control}
              render={({ field }) => (
                <>
                  <div>
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="English"
                      autoCapitalize={"none"}
                    />
                    {errors?.titleEnglish?.message && (
                      <FormHelperText error>
                        {errors?.titleEnglish?.message}
                      </FormHelperText>
                    )}
                  </div>
                </>
              )}
              name={"titleEnglish"}
              rules={{
                required: requiredError,
              }}
              defaultValue={formDataInit.titleEnglish}
            />
          </div>
        </div>

        <div>
          <FormControl component="fieldset">
            <Box my={1}>
              <Typography variant="body1">
                <strong>Link type</strong>
              </Typography>
            </Box>
            <Controller
              key={"destination"}
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  className={"flex"}
                  aria-label="type"
                  name="type"
                >
                  <FormControlLabel
                    value={MenuItemType.internalWeb}
                    control={<Radio />}
                    label="Internal Link"
                  />
                  <FormControlLabel
                    value={MenuItemType.externalWeb}
                    control={<Radio />}
                    label="External Link"
                  />
                  <FormControlLabel
                    value={MenuItemType.htmlContent}
                    control={<Radio />}
                    label="HTML content"
                  />
                  <FormControlLabel
                    value={MenuItemType.nativeModule}
                    control={<Radio />}
                    label="Native module"
                  />
                </RadioGroup>
              )}
              name={"type"}
              rules={{
                required: false,
              }}
              defaultValue={formDataInit.type}
            />
          </FormControl>
        </div>

        <div>
          <Controller
            key={"destination"}
            control={control}
            render={({ field }) => (
              <>
                {(watch("type") === MenuItemType.internalWeb ||
                  watch("type") === MenuItemType.externalWeb) && (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Destination"
                    autoCapitalize={"none"}
                    error={!!errors?.destination?.message}
                  />
                )}

                {watch("type") === MenuItemType.htmlContent && (
                  <WYSIWYGEditor
                    defaultValue={formDataInit.destination}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                )}

                {watch("type") === MenuItemType.nativeModule && (
                  <Select
                    label={"destination"}
                    className={"p-1 mb-3"}
                    {...field}
                    labelId="destination"
                    fullWidth
                    defaultValue={""}
                    error={!!errors?.destination?.message}
                  >
                    {getMenuItemNativeTypes().map((nativeType) => (
                      <MenuItem key={nativeType} value={nativeType}>
                        {nativeType}
                      </MenuItem>
                    ))}
                  </Select>
                )}

                {errors?.destination?.message && (
                  <FormHelperText error>
                    {errors?.destination?.message}
                  </FormHelperText>
                )}
              </>
            )}
            name={"destination"}
            rules={{
              required: requiredError,
            }}
            defaultValue={formDataInit.destination}
          />
        </div>

        <Box my={2}>
          <Button
            disabled={
              createMutationResult.isLoading || updateMutationResult.isLoading
            }
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {menuItem === undefined ? "Create" : "Update"}
          </Button>
        </Box>

        <BackDropFullScreen
          isOpen={
            createMutationResult.isLoading || updateMutationResult.isLoading
          }
        />
      </form>
    </div>
  );
}

export default MenuForm;
