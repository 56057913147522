import React, { useState } from "react";
import { useDeleteAdminById, useGetAdmins } from "../../queries/useAdmin";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AdminUserResponseDO } from "../../models/admin/AdminUserResponseDO";
import DeleteIcon from "@material-ui/icons/Delete";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import ConfirmDialog from "../../components/ConfirmDialog";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function AdminsPage() {
  const { data: admins, isLoading } = useGetAdmins();
  const delMutationResult = useDeleteAdminById();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [toDeleteAdmin, setToDeleteAdmin] = useState<AdminUserResponseDO>();

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    admin: AdminUserResponseDO
  ) => {
    history.push(`/admins/${admin.adminId}`);
  };

  const onCreate = () => {
    history.push("/admins/create");
  };

  const onEdit = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    admin: AdminUserResponseDO
  ) => {
    event.stopPropagation();
    history.push(`/admins/${admin.adminId}/edit`);
  };

  const onDelete = async () => {
    try {
      await delMutationResult.mutateAsync(toDeleteAdmin?.adminId as number);
      await queryClient.invalidateQueries("useGetAdmins");
      enqueueSnackbar(
        `Delete admin ${toDeleteAdmin?.firstname} successfully!`,
        {
          variant: "success",
        }
      );
    } catch (e: any) {
      console.error(e.message);
      enqueueSnackbar(
        `Delete admin ${toDeleteAdmin?.firstname} with error: ${e.message}`,
        {
          variant: "error",
        }
      );
    }
  };

  const openDialog = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    admin: AdminUserResponseDO
  ) => {
    event.stopPropagation();
    setOpen(true);
    setToDeleteAdmin(admin);
  };

  return (
    <div className={"w-full flex justify-center"}>
      <div className={"max-w-4xl"}>
        <div className={"flex justify-between mb-4"}>
          <Typography variant={"h5"}>Administratoren</Typography>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onCreate}
          >
            Erstellen
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Vorname</TableCell>
                <TableCell align="right">Nachname</TableCell>
                <TableCell align="right">E-Mail</TableCell>
                <TableCell align="right">Erstellt am</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins &&
                admins.map((admin: AdminUserResponseDO) => (
                  <TableRow
                    className={"cursor-pointer"}
                    hover
                    key={admin?.adminId}
                    onClick={(event) => onRowClick(event, admin)}
                  >
                    <TableCell component="th" scope="row">
                      {admin?.firstname}
                    </TableCell>
                    <TableCell align="right">{admin?.lastname}</TableCell>
                    <TableCell align="right">{admin?.email}</TableCell>
                    <TableCell align="right">
                      {moment(admin?.createdAt).format("DD.MM.YYYY - hh:mm")}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={(event) => onEdit(event, admin)}>
                        <PageviewOutlinedIcon color={"primary"} />
                      </IconButton>

                      <IconButton onClick={(event) => openDialog(event, admin)}>
                        <DeleteIcon color={"error"} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <ConfirmDialog
        isOpen={open}
        title={"Administrator löschen"}
        content={"Möchten Sie dies wirklich tun?"}
        onOk={onDelete}
        onCancel={() => setOpen(false)}
      />
      <BackDropFullScreen isOpen={isLoading} />
    </div>
  );
}

export default AdminsPage;
