import React, { useState } from "react";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
  useDeleteCategoryById,
  useGetCategories,
} from "../../queries/useCategory";
import { PushNotificationCategory } from "../../models/category/PushNotificationCategory";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function CategoriesPage() {
  const { data: categories, isLoading } = useGetCategories();
  const delMutationResult = useDeleteCategoryById();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [toDelete, setToDelete] = useState<PushNotificationCategory>();

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    category: PushNotificationCategory
  ) => {
    history.push(`/categories/${category.pushNotificationCategoryId}`);
  };

  const onCreate = () => {
    history.push("/categories/create");
  };

  const onEdit = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    category: PushNotificationCategory
  ) => {
    event.stopPropagation();
    history.push(`/categories/${category.pushNotificationCategoryId}/edit`);
  };

  const onDelete = async () => {
    try {
      await delMutationResult.mutateAsync(
        toDelete?.pushNotificationCategoryId as number
      );
      await queryClient.invalidateQueries("useGetCategories");
      enqueueSnackbar(
        `Delete category ${toDelete?.categoryShort} successfully!`,
        {
          variant: "success",
        }
      );
    } catch (e: any) {
      console.error(e.message);
      enqueueSnackbar(
        `Delete category ${toDelete?.categoryShort} with error: ${e.message}`,
        {
          variant: "error",
        }
      );
    }
  };

  const openDialog = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    category: PushNotificationCategory
  ) => {
    event.stopPropagation();
    setOpen(true);
    setToDelete(category);
  };

  return (
    <div className={"w-full flex justify-center"}>
      <div className={"max-w-4xl"}>
        <div className={"flex justify-between mb-4"}>
          <Typography variant={"h5"}>Push-Kategorien</Typography>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onCreate}
          >
            Erstellen
          </Button>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="right">Kurzbeschreibung</TableCell>
                <TableCell align="right">Beschreibung</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories &&
                categories.map((category: PushNotificationCategory) => (
                  <TableRow
                    className={"cursor-pointer"}
                    hover
                    key={category?.pushNotificationCategoryId}
                    onClick={(event) => onRowClick(event, category)}
                  >
                    <TableCell component="th" scope="row">
                      {category?.categoryIdentifier}
                    </TableCell>
                    <TableCell align="right">
                      {category?.categoryShort}
                    </TableCell>
                    <TableCell align="right">
                      {category?.categoryDescription}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={(event) => onEdit(event, category)}>
                        <PageviewOutlinedIcon color={"primary"} />
                      </IconButton>

                      <IconButton
                        onClick={(event) => openDialog(event, category)}
                      >
                        <DeleteIcon color={"error"} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <ConfirmDialog
        isOpen={open}
        title={"Delete Category"}
        content={"Still wanna delete?"}
        onOk={onDelete}
        onCancel={() => setOpen(false)}
      />
      <BackDropFullScreen isOpen={isLoading} />
    </div>
  );
}

export default CategoriesPage;
