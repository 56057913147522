import React, { useEffect } from "react";
import { Box, Container, FormHelperText, Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BackDropFullScreen from "../../components/BackDropFullScreen";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { DatePicker } from "@material-ui/pickers";
import { EventAdminRequestDO } from "../../models/event/EventAdminRequestDO";
import { EventPrivateDO } from "../../models/event/EventPrivateDO";
import { useCreateEvent, useUpdateEvent } from "../../queries/useEvent";
import { WYSIWYGEditor } from "../../components/WYSIWYGEditor";
import moment from "moment";

type EventFormType = {
  action: "DETAIL" | "EDIT" | "CREATE";
  event?: EventPrivateDO;
};

const formDataInit: EventAdminRequestDO = {
  isPublished: false,
  title: "",
  city: "",
  description: "",
  location: "",
  startDate: new Date(),
  endDate: new Date(),
  startTime: "00:00",
  endTime: "00:00",
  link: "",
  image: "",
};

function EventForm({ action, event }: EventFormType) {
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const createMutationResult = useCreateEvent();
  const updateMutationResult = useUpdateEvent();
  const queryClient = useQueryClient();
  const requiredError = "Please fill out the field";
  const invalidHourError = "Hour format HH:MM";
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EventAdminRequestDO>();

  useEffect(() => {
    reset({
      isPublished: false,
      title: "",
      city: "",
      description: "",
      location: "",
      startDate: new Date(),
      endDate: new Date(),
      startTime: "00:00",
      endTime: "00:00",
      link: "",
      image: "",
    });
    if (event !== undefined && action !== "CREATE") {
      formDataInit.title = event.title;
      formDataInit.isPublished = event.isPublished;
      formDataInit.city = event.city;
      formDataInit.description = event.description;
      formDataInit.location = event.location;
      formDataInit.startDate = event.startDate as Date;
      formDataInit.endDate = event.endDate as Date;
      formDataInit.startTime = event.startTime as Date;
      formDataInit.endTime = event.endTime as Date;
      formDataInit.link = event.link;
      formDataInit.image = event.image;
      formDataInit.creator = event.creator;
      reset(formDataInit);
    }
  }, [event, reset, action]);

  const transformDateTime = (
    data: EventAdminRequestDO
  ): EventAdminRequestDO => {
    data.startDate = moment(data?.startDate).format("yyyy-MM-DD");
    // data.startTime = moment(data?.startTime).format("HH:MM");
    data.endDate = moment(data?.endDate).format("yyyy-MM-DD");
    // data.endTime = moment(data?.endTime).format("HH:MM");
    return data;
  };

  const onSubmit = async (data: EventAdminRequestDO) => {
    if (eventId) {
      data.id = parseInt(eventId);
    }
    data = transformDateTime(data);

    try {
      switch (action) {
        case "CREATE":
          await onCreate(data);
          break;
        case "EDIT":
          await onEdit(data);
          break;
      }
    } catch (e: any) {
      console.error(e.message);
      enqueueSnackbar(`Error: ${e.message}`, {
        variant: "error",
      });
    }
  };

  async function onEdit(data: EventAdminRequestDO) {
    await updateMutationResult.mutateAsync(data);
    await queryClient.invalidateQueries("useGetEvents");
    await queryClient.invalidateQueries("useGetEventProposals");
    await queryClient.invalidateQueries(["useGetEventById", data.id]);
    enqueueSnackbar(`Event ${data.title} updated successfully!`, {
      variant: "success",
    });
    history.push("/events");
  }

  async function onCreate(data: EventAdminRequestDO) {
    await createMutationResult.mutate(data);
    enqueueSnackbar(`Event ${data.title} created successfully!`, {
      variant: "success",
    });
    history.push("/events");
    setTimeout(async () => {
      await queryClient.invalidateQueries("useGetEvents");
      await queryClient.invalidateQueries("useGetEventProposals");
      await queryClient.invalidateQueries([
        "useGetEventById",
        createMutationResult.data?.id || "",
      ]);
    }, 1000);
  }

  const showHeading = () => {
    switch (action) {
      case "CREATE":
        return (
          <Typography component="h1" variant="h5">
            Event Create
          </Typography>
        );
      case "DETAIL":
        return (
          <Typography component="h1" variant="h5">
            Event Detail
          </Typography>
        );
      case "EDIT":
        return (
          <Typography component="h1" variant="h5">
            Event Edit
          </Typography>
        );
    }
  };

  return (
    <Container className={""} component="main" maxWidth="md">
      <div>
        {showHeading()}
        <form className={"w-full"}>
          <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
            <div className={""}>
              <Controller
                key={"title"}
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Title"
                      inputProps={{ readOnly: action === "DETAIL" }}
                      autoCapitalize={"none"}
                      error={!!errors?.title?.message}
                    />
                    {errors?.title?.message && (
                      <FormHelperText error>
                        {errors?.title?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
                name={"title"}
                rules={{
                  required: requiredError,
                }}
                defaultValue={formDataInit.title}
              />

              <div className={"grid grid-cols-2 gap-2 mt-2"}>
                <Controller
                  key={"startDate"}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <DatePicker
                        {...field}
                        clearable
                        fullWidth
                        format="EE, dd. MMMM yyyy"
                        margin="normal"
                        id="startDate"
                        label="Start date"
                        readOnly={action === "DETAIL"}
                        error={!!errors?.startDate?.message}
                      />
                      {errors?.startDate?.message && (
                        <FormHelperText error>
                          {errors?.startDate?.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                  name={"startDate"}
                  rules={{
                    required: false,
                  }}
                  defaultValue={formDataInit.startDate}
                />

                <Controller
                  key={"startTime"}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <TextField
                        {...field}
                        fullWidth
                        margin="normal"
                        id="startTime"
                        label="Start time"
                        error={!!errors?.startTime?.message}
                        inputProps={{
                          readOnly: action === "DETAIL",
                        }}
                      />
                      {errors?.startTime?.message && (
                        <FormHelperText error>
                          {errors?.startTime?.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                  name={"startTime"}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^([0-1]\d|20|21|22|23):[0-5]\d$/,
                      message: invalidHourError,
                    },
                  }}
                  defaultValue={formDataInit.startTime}
                />
              </div>

              <div className={"grid grid-cols-2 gap-2 mt-2 mb-4"}>
                <Controller
                  key={"endDate"}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <DatePicker
                        {...field}
                        clearable
                        fullWidth
                        format="EE, dd. MMMM yyyy"
                        margin="normal"
                        id="endDate"
                        label="End date"
                        readOnly={action === "DETAIL"}
                        error={!!errors?.endDate?.message}
                      />
                      {errors?.endDate?.message && (
                        <FormHelperText error>
                          {errors?.endDate?.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                  name={"endDate"}
                  rules={{
                    required: false,
                  }}
                  defaultValue={formDataInit.endDate}
                />

                <Controller
                  key={"endTime"}
                  control={control}
                  render={({ field }) => (
                    <div>
                      <TextField
                        {...field}
                        fullWidth
                        margin="normal"
                        id="endTime"
                        label="End time"
                        error={!!errors?.endTime?.message}
                        inputProps={{
                          readOnly: action === "DETAIL",
                        }}
                      />
                      {errors?.endTime?.message && (
                        <FormHelperText error>
                          {errors?.endTime?.message}
                        </FormHelperText>
                      )}
                    </div>
                  )}
                  name={"endTime"}
                  rules={{
                    required: false,
                    pattern: {
                      value: /^([0-1]\d|20|21|22|23):[0-5]\d$/,
                      message: invalidHourError,
                    },
                  }}
                  defaultValue={formDataInit.endTime}
                />
              </div>
            </div>

            <div className={""}>
              <Controller
                key={"city"}
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="City"
                      inputProps={{ readOnly: action === "DETAIL" }}
                      autoCapitalize={"none"}
                      error={!!errors?.city?.message}
                    />
                    {errors?.city?.message && (
                      <FormHelperText error>
                        {errors?.city?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
                name={"city"}
                rules={{
                  required: false,
                }}
                defaultValue={formDataInit.city}
              />

              <Controller
                key={"location"}
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      label="Location"
                      inputProps={{ readOnly: action === "DETAIL" }}
                      autoCapitalize={"none"}
                      error={!!errors?.location?.message}
                    />
                    {errors?.location?.message && (
                      <FormHelperText error>
                        {errors?.location?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
                name={"location"}
                rules={{
                  required: false,
                }}
                defaultValue={formDataInit.location}
              />
            </div>
          </div>

          <div className={"grid grid-cols-12 gap-4"}>
            <div
              className={
                event?.creator !== undefined && event?.creator !== null
                  ? "col-start-1 col-end-7"
                  : "col-start-1 col-end-13"
              }
            >
              <Typography component="h2" variant="h6">
                Description
              </Typography>
              <Controller
                key={"description"}
                control={control}
                render={({ field }) => (
                  <>
                    {action === "DETAIL" && (
                      <div
                        className={"mt-4"}
                        dangerouslySetInnerHTML={{ __html: field.value }}
                      />
                    )}

                    {action !== "DETAIL" && (
                      <WYSIWYGEditor
                        defaultValue={event?.description}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    )}

                    {errors?.description?.message && (
                      <FormHelperText error>
                        {errors?.description?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
                name={"description"}
                rules={{
                  required: false,
                }}
                defaultValue={formDataInit.description}
              />
            </div>

            {event?.creator !== undefined && event?.creator !== null && (
              <div className={"col-start-8 col-end-12 space-y-3 "}>
                <Typography component="h2" variant="h6">
                  Reported by
                </Typography>

                <Typography component="h2" variant="body1">
                  {event?.creator?.firstName} {event?.creator?.lastName}
                </Typography>
                <Typography component="h2" variant="body1">
                  {event?.creator?.street}
                </Typography>
                <Typography component="h2" variant="body1">
                  {event?.creator?.postalCode} {event?.creator?.city}
                </Typography>
                <Typography component="h2" variant="body1">
                  Tel.: <Link>{event?.creator?.phone} </Link>
                </Typography>
                <Typography component="h2" variant="body1">
                  eMail: <Link>{event?.creator?.email}</Link>
                </Typography>
              </div>
            )}
          </div>

          <Box my={2}>
            {action !== "DETAIL" && (
              <Button
                disabled={
                  createMutationResult.isLoading ||
                  updateMutationResult.isLoading
                }
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                {action === "EDIT" ? "Save" : "Create"}
              </Button>
            )}
          </Box>

          <BackDropFullScreen
            isOpen={
              createMutationResult.isLoading || updateMutationResult.isLoading
            }
          />
        </form>
      </div>
    </Container>
  );
}

export default EventForm;
